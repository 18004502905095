import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const StatusBanner = makeShortcode("StatusBanner");
const Checklist = makeShortcode("Checklist");
const List = makeShortcode("List");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <StatusBanner warning mdxType="StatusBanner">
      <p><strong parentName="p">{`The grants program is not accepting new applications at the moment. Please check back later for the next wave.`}</strong></p>
    </StatusBanner>
    <h1>{`Grantee Playbook`}</h1>
    <p>{`Here’s how to ace the development grants program so you can hit your milestones, get funding and ship your vision.`}</p>
    <h2>{`Path to Success`}</h2>
    <p>{`Grantee funds are released upon the completion of predetermined milestones. Do the following to ensure your next block of allocated funds:`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Follow this framework and other requirements docs sent from the team.`}</p>
      <p>{`Keep up your `}<a parentName="p" {...{
          "href": "/funding/development-grants/grantee-playbook#code-repo-activity"
        }}>{`code repo active`}</a></p>
      <p>{`Complete your roadmap items and show progress via commits or issues.`}</p>
      <p>{`Hit milestones on time`}{`(`}{`ish`}{`)`}</p>
      <p>{`Document your activity with `}<a parentName="p" {...{
          "href": "/funding/development-grants/grantee-playbook#regular-dev-posts"
        }}>{`regular development posts.`}</a></p>
      <p>{`Build a `}<a parentName="p" {...{
          "href": "/funding/development-grants/grantee-playbook#social-media-presence"
        }}>{`social media presence`}</a></p>
      <p>{`Get to know and help out other grantees, join and post regularly in the `}<a parentName="p" {...{
          "href": "/funding/development-grants/grantee-playbook#post-regularly-in-chat-makerdao-com"
        }}>{`grantee chat.`}</a></p>
      <p>{`Actually launch the thing!`}</p>
    </Checklist>
    <p>{`Successful grantees can receive more than funding. Teams that successfully complete their milestones and launch a product may also gain access to internal MakerDAO resources which can take many forms from code reviews and external security audits, to consultations with Legal, Public Relations and/or Business Development teams. Successful projects will also be prioritized for continued granting and or possible investments from MakerDAO in future iterations of the program.`}</p>
    <h2>{`Successful Grantee Examples`}</h2>
    <p>{`Here are some successful grantees:`}</p>
    <List mdxType="List">
      <p><a parentName="p" {...{
          "href": "https://xdai.io/"
        }}>{`Burner Wallet`}</a>{` A mobile phone can send DAI to another in 5 seconds with a simple QR code scan.`}</p>
      <p><a parentName="p" {...{
          "href": "https://sablier.finance/"
        }}>{`Sablier`}</a>{` A decentralised app for continuous payments.`}</p>
      <p><a parentName="p" {...{
          "href": "https://www.pooltogether.com/"
        }}>{`Pool Together`}</a>{` A no-loss, audited savings game powered by blockchain technology.`}</p>
    </List>
    <h2>{`Social Media Presence`}</h2>
    <p>{`Cross-promotion is important when getting the word out about the cool projects you are working on. Please follow, like, add, clap, upvote when your fellow teams publish something. This increased visibility will allow MakerDAO to form PR plans around notable events and product launches.`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Set up a Twitter handle for your project.`}</p>
      <p>{`Try to maintain, at least, weekly activity in the account.`}</p>
      <p>{`Follow projects in your space`}</p>
      <p>{`Follow other grantees`}</p>
      <p>{`Identify influencers who might be interested in your project and add them.`}</p>
      <p>{`Share links to your blog posts and repositories`}</p>
    </Checklist>
    <h3>{`Examples`}</h3>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/sablierhq?lang=en"
      }}>{`Sablier`}</a></p>
    <h2>{`Code Repo Activity`}</h2>
    <p>{`The Community Development team will primarily be watching activity in grantee repos to measure project velocity.`}</p>
    <Checklist mdxType="Checklist">
      <p>{`Ensure all work is being regularly committed to a repo`}</p>
      <p>{`Regularly track and close issues and pull requests`}</p>
      <p>{`If your repo is private, add `}<a parentName="p" {...{
          "href": "https://github.com/amyjung"
        }}>{`@amyjung`}</a></p>
      <p>{`Create a top-level `}<inlineCode parentName="p">{`README.md`}</inlineCode>{` which contains a detailed description of the project, goals, features, roadmap and installation instructions.`}</p>
    </Checklist>
    <h2>{`Regular Development Posts`}</h2>
    <p>{`Each project needs a dedicated blog. `}<a parentName="p" {...{
        "href": "https://substack.com/"
      }}>{`Substack,`}</a>{` `}<a parentName="p" {...{
        "href": "https://www.medium.com"
      }}>{`Medium,`}</a>{` and `}<a parentName="p" {...{
        "href": "https://ghost.org/"
      }}>{`Ghost`}</a>{` are good options.`}</p>
    <p>{`Set up a reminder to write a post at least once a month, or once per milestone, outlining the progress/challenges/wins, your team has encountered. Include links to repos, demos and social media`}</p>
    <h3>{`Examples`}</h3>
    <p><a parentName="p" {...{
        "href": "https://medium.com/gitcoin/ethereum-in-emerging-economies-b235f8dac2f2"
      }}>{`Etehreum in Emerging Economies`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://medium.com/daipos/every-day-crypto-with-xdai-a832b051a65"
      }}>{`Every Day Crypto with xDAI`}</a></p>
    <h2>{`Post Regularly in chat.makerdao.com`}</h2>
    <p>{`The chat brings together the entire Maker community `}{`(`}{`potentially your future customers`}{`)`}{` and experts on Maker.
`}<a parentName="p" {...{
        "href": "https://chat.makerdao.com/group/comm-dev-incubate"
      }}>{`Comm-Dev-Incubate Chat`}</a></p>
    <p>{`Make sure you and your team join the `}{`#`}{`comm-dev-incubate channel. There are many teams working on projects complimentary to the Dai ecosystem so there is a good chance someone there has some ideas about problems you might be facing. It's also a great opportunity to find ways to integrate with other projects in the space.`}</p>
    <p>{`Please post all status updates, tweets, questions in `}{`#`}{`comm-dev-incubate so other grantees can jam on whatever is going on and share social media love.`}</p>
    <p><a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/dev"
      }}>{`Dev Chat`}</a></p>
    <p>{`Ask technical questions in the MakerDAO `}{`#`}{`dev channel. The people who built the core MakerDAO ecosystem are all in there, and available for questions.`}</p>
    <h2>{`Questions?`}</h2>
    <p>{`If you need more info reach out to us on the community chat.`}</p>
    <p><a parentName="p" {...{
        "href": "https://chat.makerdao.com/channel/community-development"
      }}>{`Community Chat`}</a></p>
    {
      /*
      ## Apply for a Grant
      Ready to submit your application for a development grant?
      [Submit application](/funding/development-grants/development-grants-info)
      */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      